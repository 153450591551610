import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import SEO from "../components/seo"
import Hero from "../components/heroOffer"
import Banner from "../components/banner"
import Verhuiswagen from "../components/verhuiswagen"
import Verhuizer from "../components/verhuizer"
import Testimonials from "../components/testimonials"
import Pricing from "../components/pricing"

import Lift from "../components/lift"
import StickyCTA from "../components/stickyCTA"

const StyledPricing = styled(Pricing)`
  > .price__title {
    display: none;
  }
  > .price__cta {
    display: none;
  }
`

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter } = markdownRemark

  const heroUsps = [
    "Verhuizer en verhuiswagen €35 p.u.",
    "Verhuizer €25 p.u.",
    "Verhuislift €75 p.u.",
  ]

  const testimonials = frontmatter.testimonials.map(testimonial => ({
    title: testimonial.title,
    image: <Img fluid={testimonial.image.childImageSharp.fluid} />,
  }))

  return (
    <>
      <SEO title={`Onze verhuistarieven ${frontmatter.title}`} />
      <Hero
        usps={heroUsps}
        img={
          <Img
            objectFit="cover"
            objectPosition="50% 50%"
            fluid={frontmatter.heroImage.childImageSharp.fluid}
          />
        }
      >
        <h1 className="font-weight-ligt">Onze</h1>
        <h1 className="font-weight-bold">verhuistarieven</h1>
      </Hero>

      <section className="bg-white d-md-none">
        <div className="row no-gutters">
          <div className="col-12">
            <StyledPricing></StyledPricing>
          </div>
        </div>
      </section>

      <section className="section--lg bg-white">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6">
              <h5 className="font-weight-bold mb-1">
                Verhuizer en verhuiswagen
              </h5>
              <p className="mb-3">
                Onze tarieven zijn € 35 per uur voor 1 verhuizer met een
                verhuiswagen.
              </p>
              <div className="price-box bg-white py-3 mb-6">
                <ul className="prices text-left">
                  <li className="price mb-0">
                    <div className="price__icon">
                      <Verhuiswagen />
                    </div>
                    <div className="price__description">
                      <h5 className="font-weight-light">
                        Verhuizer en verhuiswagen
                      </h5>
                    </div>
                    <div className="price__price">
                      <h5 className="font-weight-light">
                        <b>€35</b> p.u.
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>

              <h5 className="font-weight-bold mb-1">Verhuizer</h5>
              <p className="mb-3">
                Voor iedere extra verhuizer die u nodig heeft betaalt u € 25 per
                uur.
              </p>
              <div className="price-box bg-white py-3 mb-6">
                <ul className="prices text-left">
                  <li className="price mb-0">
                    <div className="price__icon">
                      <Verhuizer />
                    </div>
                    <div className="price__description">
                      <h5 className="font-weight-light">Verhuizer</h5>
                    </div>
                    <div className="price__price">
                      <h5 className="font-weight-light">
                        <b>€25</b> p.u.
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>
              <h5 className="font-weight-bold mb-1">Verhuislift</h5>
              <p className="mb-3">
                Afhankelijk van de situatie is het ook mogelijk om een
                verhuislift te betrekken bij uw verhuizing. Hiervoor betaald u €
                75 per uur.
              </p>
              <div className="price-box bg-white py-3">
                <ul className="prices text-left">
                  <li className="price mb-0">
                    <div className="price__icon">
                      <Lift />
                    </div>
                    <div className="price__description">
                      <h5 className="font-weight-light">Verhuislift</h5>
                    </div>
                    <div className="price__price">
                      <h5 className="font-weight-light">
                        <b>€75</b> p.u.
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials testimonials={testimonials}></Testimonials>
      <Banner />
      <StickyCTA></StickyCTA>
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        testimonials {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`
