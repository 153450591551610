// Icon
import React from "react"

function Icon({ className }) {
  return (
    <svg fill="none" height="64" viewBox="0 0 74 64" width="74">
      <pattern
        id="verhuizer"
        height="1"
        patternContentUnits="objectBoundingBox"
        width="1"
      >
        <image
          height="37"
          transform="matrix(.0712531 0 0 .027027 -.034398 0)"
          width="15"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAlCAYAAACd4bMaAAAEA0lEQVRIS6WWWUhjVxjH/58Lrg8uUV9SXEahgrRSbXEvCG642wcp0vFFCqVQqFGqRlMoVKqJilCcgG/tQ/uiVDRSQeO4IO1AGWQEt7hVxSiJCwqCNjnlO3jvJHFssT0vWe79fcv//L97D+ENSwiRCeAzAO8B2ADwjIie+95Kvn8IIZrtdnvf7OwsDg8PERMTg/z8fCQlJX1DRF973u8FCyESnU7nttlshsPhUO8LCwtDU1MTEhISsonoN+WCL/yV1Wr9bnx8HEIIEJH6mZWVhfr6+mEi+vQh+FuLxdIxPT19T4n09HQ0Njb+TEQfPwR/aLPZng8PD+Pm5sYrQENDAzIzM58S0Y/3YCHEEwCJAEpXVlZ0XPrl5SWCgoJQWFjIoj0DMArgTyLiHYDsWQihczgcprOzMxk0MDAQp6enOD8/R3h4uFTc5XLB7XYjIiICsbGxbUTUQ0KIT1ZXV38YGRmB0+mUMIsVEBAAf39/Cdze3sr/WcCoqCjU1tYiLS2tieGlwcHB7N3dXRVUe6LXm6Goz9e0Wi10Ot0fDK92d3e/fXJyIiMry3erPNXjNjo6OtYZftXT05Nmt9uRnJwszcG9ei4OFBISwibB2toa94z29vY1FT46OkJFRYW8uLW1pbagVBMZGYmCggKMjY0hOjoanZ2dNi+4rKwM6+vr2NnZ8SqffzCcl5cH3kKNRvMa7u3tTePMJSUl2NjY8IKVKAzn5OTAYrF4Z1bg4uJibG5uSthTXSWzAnNmvV4vy142Go3v8PhxZgX2FUwpe2JiQsm8w/CL/v7+9/f39+GZ2Xcy2Fm5ubmybA5kMBh2Gf59YGDgg729PZSWlsqet7e3VUcpQTxhdllXV5eEX5pMpnQuu6ioCDab7V7P3D9ny87OxuTkpLSowWDYZ/hXs9lcwltUU1OD5eVlVW1P0TgzT9fo6Cji4uLQ1ta2ynC10+n85eDgACkpKRgaGgJ/Z3OkpqbKQNfX1wgNDUVzc7N0IE+aVqv9QhlJnuV33W73iNFoBFuVV11dHWZmZnBxcSHt2dLSwiWXAjghopfqJAghAl0u143JZFJhtuvCwoL0Ome+gxOJSI7gG2F2G6+qqirMz88/DuayWSwF9in7nzMrPVdWVsqy+fH0qLJZbYa5bM4cHByM1tZWFuzhzH19fVB6VmBFMJ1Ox77+d9iz50eprQhWXV2Nubm5/1f2f1ZbEczDYQ/3zPY8Pj6WJikvL8fi4qJaNgum0WieEJGcWU+H+bvd7r98vT01NYWrqyv5CtLr9fy6eYuIDrzgu9fM50tLS99brVbEx8fLEeUTAo9pRkaGfFj4+fkFEJHrHnwX4Mu788gLPosAqAbwEYCf7s4mh8rT5W+JDMolF4ZFNwAAAABJRU5ErkJggg=="
        />
      </pattern>
      <path d="m29 12h15.1724v40h-15.1724z" fill="url(#verhuizer)" />
    </svg>
  )
}

export default Icon
